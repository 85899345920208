body
{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body
{
    margin: 0;
    padding: 0;

    /* Safari fix */
    overflow-x: hidden;

    background-color: #f6f6f6;
}

.body-link
{
    color: #8cc63f;
    
    transition: all .25s ease-out;
}

.body-link:hover
{
    cursor: pointer;
    color: #8cc63f;

    transition: all .25s ease-out;
}

/* Bootstrap 5 tweak: do not underline links unless hovered over */
a
{
    color: #8cc63f;
    text-decoration: initial;
}

a:hover
{
    cursor: pointer;
    color: #8cc63f;
}

:target
{
    scroll-margin-top: 60px;
}

.noselect
{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}