.navbar-container
{
    padding-top: 0px;
    padding-bottom: 0px;

    min-height: 60px;

    justify-content: space-between;
}

.navbar-item
{
    font-weight: 700;
    font-size: 18px;
    color: #000000;
}

/* Full height */
.navbar-nav
{
    min-height: 60px;
}

.navbar-previous
{
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.navbar-previous:hover
{
    cursor: pointer;

    text-decoration: underline;
    
    color: #000000;
}

.navbar-previous-icon
{   
    display: inline-block;

    width: 12px;
    height: 12px;
    margin-right: 4px;

    background-image: url('/public/image/icon_chevronleft.svg');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center;
}

.navbar-reviews
{
    margin-left: 12px;

    width: 300px;
    height: 50px;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}

.navbar-section
{
    background: #ffffff;
}

.navbar-spacing
{
    margin-top: 69px;
}

.navbar-subtitle
{
    display: inline-block;
    
    color: rgb(0, 0, 0);
    
    font-size: 16px;
}

.navbar-title-container
{
    display:flex;
    align-items:center;
}

.navbar-title
{
    display: inline-block;

    width: 185px;
    height: 24px;
    
    background-image: url('/public/image/logo_recharged.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
}

@media only screen and (max-width: 600px)
{
    .navbar-title
    {
        width: 140px;
    }
}

.navbar-title-seperator
{
    display: inline-block;

    margin-left: 12px;
    margin-right: 12px;
    
    width: 1px;
    height: 32px;

    background-color: #000000;
}